import { mapActions, mapGetters } from "vuex"

export default {
  name: 'task-results',
  data() {
    return {
      heading: '',
      id: '',
      info: null,
      hasTimeDelay: false,
      targetOrders: 0
    }
  },
  computed: {
    ...mapGetters(['getOrderTarget'])
  },
  async mounted() {
    const { heading, hasTimeDelay, targetOrders, id } = this.$route.query
    this.heading = heading
    this.id = id
    if (hasTimeDelay) this.hasTimeDelay = (hasTimeDelay === 'true') ? true : false
    if (targetOrders) this.targetOrders = targetOrders

    this.info = await this.fetchTaskInfoById(this.id)
  },
  methods: {
    ...mapActions(['fetchTaskInfoById']),
    calculateEarnings() {
      let count = 0
      if (this.info.hasBonus && this.info.correctOrderCount >= this.info.targetOrders) {
        count += 1
        const correctOrdersOverTarget = this.info.correctOrderCount - this.info.targetOrders
        count += correctOrdersOverTarget * 0.05
      } else {
        count += this.info.correctOrderCount * 0.05
      }
      return count.toFixed(2)
    },
    loadNextPage() {
      if (this.id === 'p1') {
        this.$router.push(`/task?id=p2&heading=${encodeURI('Second Practice Period')}&hasTimeDelay=false`)
      } else if (this.id === 'p2') {
        this.$router.push(`/pay-details`)
      } else if (this.id === 'w1') {
        this.$router.push(`/task?id=w2&heading=${encodeURI('Work Period 2')}&targetOrders=${this.getOrderTarget}&hasTimeDelay=true`)
      } else if (this.id === 'w2') {
        this.$router.push(`/unfinished`)
      }
    }
  }
}